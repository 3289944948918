@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'RetroFunk';
  src: url('../assets/fonts/Retrofunkscriptpersonaluse-v6XO.otf');
}

@font-face {
  font-family: 'Brandon';
  src: url('../assets/fonts/BrandonText-Regular.otf');
}

@font-face {
  font-family: 'BrandonBold';
  src: url('../assets/fonts/BrandonText-Bold.otf');
}

@font-face {
  font-family: 'BrandonItalic';
  src: url('../assets/fonts/BrandonText-RegularItalic.otf');
}

div,
h2,
h3,
h4,
h5,
p,
span,
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h,
.native-input.sc-ion-input-md,
.native-input.sc-ion-input-ios,
ion-alert,
ion-content,
ion-item,
ion-label,
ion-card,
ion-card-header,
ion-card-title,
ion-card-subtitle,
ion-card-content,
ion-grid,
ion-row,
ion-col,
ion-searchbar,
ion-toolbar,
ion-segment,
ion-segment-option,
ion-select,
ion-select-option,
ion-button,
ion-item-divider,
ion-list,
ion-list-header,
ion-text,
ion-badge,
ion-chip,
ion-input,
ion-popover,
ion-note,
ion-textarea {
  font-family: 'Brandon', sans-serif;
}
ion-item > ion-label {
  font-family: 'Brandon', sans-serif;
}

ion-title {
  font-family: 'BrandonBold', sans-serif !important;
}

h1 {
  font-family: 'BrandonBold', sans-serif !important;
}

/* ion-popover {
  --background: rgba(0, 0, 0, 1) !important;
} */

ion-tab-bar#mainTabBar {
  --background: #333 !important;
}

ion-tab-button {
  --color: #fff !important;
  --color-selected: #FC0FC0 !important;
}

ion-select::part(text) {
  font-size: '2vh',
}

.desktop {
  margin: 0 10% !important;
  max-width: 80% !important;
}

.active {
  color: #FC0FC0 !important;
  font-family: 'BrandonBold';
  text-decoration: none;
}

.navLink {
  /* color: white; */
  font-family: 'BrandonBold';
  text-decoration: none;
}

.navLinkActive {
  color: #FC0FC0;
  font-family: 'BrandonBold';
  text-decoration: none;
}

.bold {
  font-family: 'BrandonBold' !important;
}

.vh100 {
  font-size: 1vh !important;
}
.vh125 {
  font-size: 1.25vh !important;
}
.vh150 {
  font-size: 1.5vh !important;
}
.vh175 {
  font-size: 1.75vh !important;
}
.vh200 {
  font-size: 2vh !important;
}
.vh225 {
  font-size: 2.25vh !important;
}
.vh250 {
  font-size: 2.5vh !important;
}
.vh275 {
  font-size: 2.75vh !important;
}
.vh300 {
  font-size: 3vh !important;
}
.vh325 {
  font-size: 3.25vh !important;
}
.vh350 {
  font-size: 3.5vh !important;
}
.vh400 {
  font-size: 4vh !important;
}
.vh450 {
  font-size: 4.5vh !important;
}
.vh500 {
  font-size: 5vh !important;
}
.black {
  color: black;
}
.onoPink {
  color: #FC0FC0 !important;
}

ion-modal#businessHourModal {
  --width: 100% !important;
  --height: 100% !important;
}

ion-modal#categoryModal {
  --width: 100% !important;
  --height: 100% !important;
}

ion-menu {
  --width: 300px !important;
  --max-width: 300px !important;
  --min-width: 300px !important;
}

ion-split-pane {
  --side-width: 300px;
  --side-max-width: 300px;
  --side-min-width: 300px;
} 

.split-pane-visible >.split-pane-side {
  min-width: 250px !important;
  max-width: 250px !important;
}

