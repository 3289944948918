ion-input.modifierItemStockInput {
  /* width: 4vw !important;
  min-width: 4vw !important;
  max-width: 4vw !important;
  height: 4vw !important; */
  border: 1px solid #999 !important;
  border-radius: 5px !important;
}

ion-item.soldOutItem::part(native) {
  /* background-color: #fff !important; */
  min-height: 20px;
  max-height: 20px;
  padding: 0 5px;
}

ion-checkbox#soldOutCheckbox {
  --border-width: 1px !important;
  width: 12px;
  height: 12px;
  margin: 0;
}

ion-checkbox#soldOutCheckbox::part(container) {
  height: 12px;
}

.customSearchBar {
  height: 40px !important;
  margin-bottom: 5px;
  --border-radius: 5px;
  background-color: '#fff';
  --background: '#fff';
}

