.preTimeCard {
  background: #c2e59c !important;
  background: linear-gradient(to right, #c2e59c, #64b3f4) !important;
  background: -webkit-linear-gradient(to right, #c2e59c, #64b3f4) !important;
  border-radius: 8px !important;
  display: table !important;
  height: 180px !important;
  text-align: center !important;
  width: 90%;
}

.createPostModal {
  --width: 100% !important;
  --height: 100% !important;
}

.uploadcare--widget__button.uploadcare--widget__button_type_open {
  visibility: hidden !important;
}

/* .alert-wrapper {
  background-color: black !important;
} */

.saleTimeSelect {
  width: '50%';
  float: left;
}

ion-select-popover {
  background-color: 'black' !important;
}

.list-md {
  background: none;
}

.saleDayInput .sc-ion-input-md-h {
  display: inline !important;
}

.swiper .swiper-slide {
  display: block !important;
}

.discountInput {
  border: 1px solid #eb445a !important;
  border-radius: 8px !important;
  width: 100px !important;
  max-width: 100px !important;
  display: inline-block;
}

.minimumInput {
  border: 1px solid grey !important;
  border-radius: 8px !important;
  width: 100px !important;
  max-width: 100px !important;
  display: inline-block;
}

.uploadcare--widget__text {
  display: none;
  font-size: 0.9em;
  margin-left: 1em;
}

ion-segment-button.segmentButton{
  font-family: 'BrandonBold' !important;
  font-size: 1.2em;
  --margin-start: 0 !important;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --color-checked: #fff;
}

ion-input.discountAmountInput {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --margin-left: 0;
}

ion-input.discountAmountInput input {
  margin: 0 !important;
  padding: 0 !important;

}