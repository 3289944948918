ion-slides {
  height: 100%;
  min-width: 100%;
}
ion-slide {
  min-width: 100%;
  height: 100%;
}



.text-black {
  color: black !important;
  font-family: 'Montserrat' !important;
}

.text-white {
  font-family: 'BrandonBold' !important;
}

.checkbox {
  border: 1px solid grey;
  border-radius: 4px;
}

.register-input {
  margin-top: 8px;
  border-bottom: 1px solid #999999;
  width: 100%;
  --padding-start: 0 !important;
  --ion-background-color: #191b1f;
}

.select-interface-option
  md
  text
  select-option
  sc-ion-select-popover
  item-radio-checked
  item
  in-list
  ion-activatable
  ion-focusable
  hydrated
  item-label {
  border-width: 0 !important;
}

/* .alert-wrapper.sc-ion-alert-md {
  width: 80% !important;
  --min-width: 300px !important;
  --background: #e5e5e5;
  color: 'white';
}
.alert-wrapper.sc-ion-alert-ios {
  width: 80% !important;
  --min-width: 300px !important;
  --background: #e5e5e5;
} */

.subtitle {
  font-size: '2em';
}

.inputItem {
  --background: 'transparent' !important;
  --background-hover:'transparent' !important;
  --inner-padding-end: 0;
}

.alert {
  --width: 70% !important;
}

.alert .alert-wrapper {
  color: 'black';
  --height: '500px';
  --background: #e5e5e5 !important;
}

.errorAlert .alert-wrapper{
  /* color: 'white' !important; */
  --background: #e5e5e5 !important;
}

.custom-styles {
  width: 80%;
  max-width: 80%;
  margin-left: 30%;
  margin-bottom: 2rem;
  --ReactInputVerificationCode-itemWidth: 7vh !important;
  --ReactInputVerificationCode-itemHeight: 7vh !important;
  --ReactInputVerificationCode-itemSpacing: 0.5rem !important;
}

