ion-card.order-card {
  width: 100%;
  border: 0.5px solid grey;
  padding: 0 !important;
}

.time-div {
  height: 1.6em;
  width: 100%;
  margin: 0;
  padding: 0.2em 1em;
}

.time-label {
  color: 'white';
  font-size: 0.8em;
  font-family: 'BrandonBold';
}

ion-modal#orderDetailModal {
  --width: 100% !important;
  --height: 100% !important;
}

.back333 {
  --background:#333;
}

.customSearchBar {
  height: 36px !important;
  /* --background: #333; */
  /* --color: #fff; */
  /* --placeholder-color: #fff; */
  /* --icon-color: #fff;
  --clear-button-color: #fff; */
  --border-radius: 20px;
  /* align-items: center !important; */
}