ion-content.menu-detail {
  --background: rgba(255, 255, 255, 0.02) !important;
}

ion-list.menu-detail {
  background-color: rgba(255, 255, 255, 0) !important;
}

ion-item > ion-label {
  color: black;
}


.text-header {
  font-size: 24px;
}

.text-black {
  color: black !important;
  font-family: 'Montserrat' !important;
}

.text-white {
  font-family: 'Montserrat' !important;
}

.checkbox {
  border: 1px solid grey;
  border-radius: 4px;
}

ion-alert {
  width: 100% !important;
}

.alert-wrapper.sc-ion-alert-md {
  width: 80% !important;
  --min-width: 300px !important;
  background-color: white;
  color: 'black';
}
.alert-wrapper.sc-ion-alert-ios {
  width: 80% !important;
  --min-width: 300px !important;
  background-color: white;
  color: 'black';
}
