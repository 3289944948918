.container {
  flex: 1;
  justify-content: 'center';
  align-items: 'center';
  text-align: 'center';
}

.mainContainer {
  flex: 1;
  width: 350px;
  padding: 12px;
  padding-top: 24px;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-input {
  margin-top: 8px;
  border-bottom: 1px solid #999999;
  --ion-background-color: #191b1f;
}

.no-padding {
  padding: 0;
}

.native-input {
  padding-left: 10px !important;
}

.submitButton {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 1.3em;
  font-weight: bolder;
  margin-top: 12px;
}

.button-outline {
  border-width: 0;
}
