ion-modal.categoryModal {
  --width: 100% !important;
  --height: 100% !important;
}

.searchBar {
  --border-radius: 10px;
}

ion-input.timeInput {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  --margin-left: 0;
}

ion-input.timeInput input {
  margin: 0 !important;
  padding: 0 !important;

}