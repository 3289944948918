.customSearchBar {
  width: '95%';
  /* --background: #333;
  --color: #fff;
  --placeholder-color: #fff;
  --icon-color: #fff;
  --clear-button-color: #fff; */
  --border-radius: 20px;
  align-items: start !important;
}

.downloadItem {
  --background: beige;
}

ion-item.downloadItem::part(detail-icon) {
  color: black;
  opacity: 1;
  font-size: 20px;
}