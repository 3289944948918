div.modal-wrapper.ion-overlay-wrapper.sc-ion-modal-md {
  width: 100% !important;
  height: 100% !important;
  min-width: none !important;
  min-height: none !important;
}

input.searchbar-input.sc-ion-searchbar-md {
  height: 36px;
}

/* ion-popover {
  background-color: 'black' !important;
} */

ion-modal#editorModal {
  --width: 100% !important;
  --height: 100% !important;
}

ion-modal#sectionCategoryModal {
  --width: 100% !important;
  --height: 100% !important;
}

.modifierModal {
  --width: 80% !important;
  --height: 90% !important;
}

/* .list-md.popover {
  background-color: rgba(0, 0, 0, 1);
} */

.list-md.modifierItemsList {
  background-color: rgba(0, 0, 0, 0);
}

.menuSegmentButtons {
  background-color: '#6a64ff' !important;
  --background-checked: '#6a64ff' !important;
  --color-checked: '#6a64ff';
}

ion-input#categoryAddInput {
  --border-radius: 15px !important;
  --border-width: 1px !important;
  --border-color: #fff !important;
  height: 30px !important;
}

.customSearchBar {
  height: 40px !important;
  margin-bottom: 5px;
  /* --background: #fff; */
  /* border-radius: 20px; */
}

.customSelect {
  /* background-color: #333 !important; */
  /* height: 36px !important; */
  border-radius: 5px !important; 
}

ion-select#select {
  --placeholder-color: #971e49 !important;
  --placeholder-opacity: 1;
  padding: 4px 0 2px 8px !important;
  border-radius: 10px !important;
  justify-content: center;
}

ion-select#customStatusSelect {
  height: 20px !important;
  border-radius: 10px !important;
  font-size: 0.75em;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

ion-item.dineInOnlyItem {
  margin: 0 12px;
  /* --padding-start: 12px;
  --padding-end: 12px; */
  --border-radius: 12px !important;
}

ion-item.dineInOnlyItem::part(native) {
  min-height: 20px;
  max-height: 20px;
  padding: 0 5px;
}

ion-checkbox#dineInOnlyCheckbox {
  --border-width: 1px !important;
  width: 12px;
  height: 12px;
  margin: 0;
}

ion-checkbox#dineInOnlyCheckbox::part(container) {
  height: 12px;
}

ion-input.stockInput {
  --placeholder-opacity: 1;
  --placeholder-font-weight: bolder;
  --padding-start: 0;
}

