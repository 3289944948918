.content {
  --background: 'black';
}

.buttonContainer {
  width: 80%;
  height: 50px;
  position: absolute;
  bottom: 24px;
  left: 10%;
}

.slideContainer {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  padding-bottom: 48px;
  box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);
  margin-top: 1.6em;
  margin-bottom: 0.9em;
  overflow: hidden;
  border-radius: 8px;
  will-change: transform;
}

.slideIframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
  padding: 0;
  margin: 0;
}

.titles {
  font-size: 0.9em;
  font-weight: 700;
  text-align: 'center';
}

.subTitles {
  font-size: 0.75em;
  font-weight: 500;
  text-align: 'center';
}

.description {
  font-size: 1.2em;
  font-weight: 700;
}

.RetroFunc {
  font-family: 'RetroFunc' !important;
}

.bold {
  font-family: 'BrandonBold';
}

.modal-container{
  border: 3px solid white !important;
  justify-content: center  !important;
  align-items: center  !important;
}

div[scrollx=true],
div[scrolly=true] {
  position: relative;
  overflow: hidden;
}

div[scrollx=true] {
  overflow-x: auto;
}

div[scrolly=true] {
  overflow-y: auto;
}