.container {
  flex: 1;
  justify-content: 'center';
  align-items: 'center';
  text-align: 'center';
}

ion-card.cardContainer {
  /* width: 350px; */
  padding: 12px;
  margin: auto;
  margin-top: 5%;
}

#popOver.list-md {
  padding: 0 !important;
}

