.container {
  flex: 1;
  justify-content: 'center';
  align-items: 'center';
  text-align: 'center';
}

/* ion-card.cardContainer {
  width: 350px;
  padding: 12px;
  margin: auto;
  margin-top: 5%;
  background-color: #191b1f !important;
} */

.email-input {
  margin-top: 8px;
  /* color: white; */
  /* padding-left: 10px !important; */
}

.comment-input {
  --padding-start: 5px !important;
  --padding-end: 5px !important;
  --border-radius: 5px !important;
  /* --background: #333; */

}

.native-input {
  padding-left: 10px !important;
}

.submitButton {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 1.3em;
  font-weight: bolder;
  margin-top: 12px;
}

.button-outline {
  border-width: 0;
}
