
ion-modal#completed-modal {
  --border-width: 10px !important;
  --border-color: #ffffff !important;
  --border-radius: 12px !important;
}

ion-modal#completed-modal h1 {
  font-size: 30px;
}


