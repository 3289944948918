.customSearchBar {
  height: 36px !important;
  /* --background: #333;
  --color: #fff;
  --placeholder-color: #fff;
  --icon-color: #fff;
  --clear-button-color: #fff; */
  --border-radius: 30px;
  min-height: 50px;
  /* align-items: center !important; */
}
.searchbar-input-container {
  min-height: 50px;
  --border-radius: 30px;
}
